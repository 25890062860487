import React, {useState} from 'react';
import Video from '../../video/video.mp4';
import { HeroContainer, HeroBg, VideoBg, HeroContent, HeroH1 ,HeroP, HeroBtnWrapper, ArrowForward, ArrowRight  } from './HeroElements';
import {Button} from '../ButtonElement'
import { useTranslation } from 'react-i18next';


const HeroSection = () => {
  const { t, i18n } = useTranslation();
  const [hover, setHover] = useState(false)

  const onHover = () => {
    setHover (!hover);
  }
  return (
    <HeroContainer id='domov' >
        <HeroBg>
            <VideoBg autoPlay loop muted src={Video} type='video/mp4' />
        </HeroBg>
        <HeroContent>
          <HeroH1>{t('Hero.1')} </HeroH1>
          <HeroP> {t('Hero.2')}</HeroP>
          
          
          <HeroBtnWrapper>
            <Button to='services' onMouseEnter= {onHover} onMouseLeave={onHover} smooth={true} duration={500} spy={true} exact='true' offset={-80}>
            {t('Hero.3')} {hover ? <ArrowForward /> : <ArrowRight  />}
            </Button>
            
          </HeroBtnWrapper>
        </HeroContent>


    </HeroContainer>
  )
}

export default HeroSection;