import React from 'react'
import { ServicesContainer, ServicesH1, ServicesH2, ServicesCard, ServicesWrapper, ServicesIcon, ServicesP } from './ServicesElements'
import Icon1 from '../../images/light.png'
import Icon2 from '../../images/water.svg'
import Icon3 from '../../images/wc.svg'

import Icon4 from '../../images/garb.svg'
import { useTranslation } from 'react-i18next';

export const Services = () => {

    const { t, i18n } = useTranslation();
  

  return (
    <ServicesContainer id='services'>
        <ServicesH1>{t('Hero.service')}</ServicesH1>
        <ServicesWrapper>
        <ServicesCard  to="reflect" smooth={true} duration={500} spy={true} exact='true' offset={-80}>
            <ServicesIcon src={Icon1} />
            <ServicesH2 > {t('Hero.service1')}</ServicesH2>
            <ServicesP> </ServicesP>
        </ServicesCard>
        <ServicesCard to="voda" smooth={true} duration={500} spy={true} exact='true' offset={-80}>
            <ServicesIcon src={Icon2}  />
            <ServicesH2 >  {t('Hero.service2')}</ServicesH2>
            <ServicesP> </ServicesP>
        </ServicesCard>
        <ServicesCard to="toalety" smooth={true} duration={500} spy={true} exact='true' offset={-80}>
            <ServicesIcon src={Icon3} />
            <ServicesH2>  {t('Hero.service3')}</ServicesH2>
            <ServicesP></ServicesP>
        </ServicesCard>
        <ServicesCard to="odpad" smooth={true} duration={500} spy={true} exact='true' offset={-80} >
            <ServicesIcon src={Icon4} />
            <ServicesH2> {t('Hero.service4')}</ServicesH2>
            <ServicesP></ServicesP>
        </ServicesCard>
        </ServicesWrapper>
       
    
    </ServicesContainer>
   
    
  )

}
