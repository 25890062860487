import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './gallery.css'
class GalleryOne extends React.Component {
    render() {
        return (
            <div className="contac">
            <div className="carot" >
                <h2></h2>
               
                <Carousel autoPlay interval="2000"infiniteLoop >
                    <div>
                        <img src="https://i.ibb.co/KjDvJ67/svetlomety3.jpg" alt="" />
                        <p className="legend">Osvětlení/Reflektory</p>
                    </div>
                    <div>
                        <img src="https://i.ibb.co/TmtmNxb/svetlomet2.jpg" alt="" />
                        <p className="legend">Osvětlení/Reflektory</p>
                    </div>
                    <div>
                        <img src="https://i.ibb.co/XZ3Rdwg/SVEtlomety.jpg" alt="" />
                        <p className="legend">Osvětlení/Reflektory</p>
                    </div>
                    <div>
                        <img src="https://i.ibb.co/z6jnwKd/Svetlomety4.jpg" alt="" />
                        <p className="legend">Osvětlení/Reflektory</p>
                    </div>
                    
                  
                </Carousel>
            </div>
            </div>
        )
    };
}
export default GalleryOne