import React, {useState} from 'react';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import HeroSection from '../components/HeroSection';
import InfoSection from '../components/InfoSection';
import { homeObjFive, homeObjFour, homeObjOne, homeObjThree, homeObjTwo } from '../components/InfoSection/Data';
import { Services } from '../components/Services';
import Footer from '../components/Footer';
import Contact from '../components/Contact/Contact';
import Gallery from '../components/Gallery'
import GalleryOne from '../components/GalleryOne';
import GalleryTwo from '../components/GalleryTwo';
import GalleryThree from '../components/GalleryThree';

import InfoSection2 from '../components/InfoSection2/index' 
import InfoSection3 from '../components/InfoSection3/index';
import InfoSection4 from '../components/InfoSection4';
import InfoSection6 from '../components/InfoSection6';
import InfoSection5 from '../components/InfoSection5';




const Home = () => {
  const [isOpen, setIsOpen] = useState(false);
  
  const toggle = () => {
    setIsOpen(!isOpen)
  };

  
  return (
            <>
        <Sidebar isOpen={isOpen} toggle={toggle} />
        <Navbar toggle={toggle}/>
        <HeroSection />
        <InfoSection2 />
      
     
        <Services />
        <InfoSection3 />
       <Gallery  />
       <InfoSection4/>
        <GalleryThree />
        
        <InfoSection5/>
      
        <GalleryTwo />
       <InfoSection6 />
        <GalleryOne />
        <Contact />

        <Footer />
        


           </>
  );
};

export default Home;




