


export const homeObjOne = {
    id: 'about',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Od roku 2011',
    headline: 'mobilnitoalety.eu',
    description: 'Mobilní toalety naše firma s dlouholetými zkušenosti v praxi, která nabízí své služby po celém světě na větší i menší akce. Disponujeme velkou šklálou strojů a vozů. Každý stroj má svojí obsluhu, vše je tedy bez starostí pro váš comfort.',
    buttonLabel: 'Zjistit více',
    imgStart: false,
    img: require('../../images/globe.png'),
    alt: 'notebook',
    dark: true,
    primary: true,
    darkText: true,

     

}



export const homeObjTwo = {
    id: 'toalet',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Vlastní nádrže na vodu a odpad',
    headline: 'Mobilní Toalety',
    description: 'Pronájem prostorných luxusních mobilních toalet včetně obsluhy. Mobilní Wc využijete při natáčení fimů, reklamy nebo pořádání společenských akcí v místech, kde nejsou k dispozici odpovídající kvalitní hygienické podmínky.',
    buttonLabel: 'Kontaktujte nás ',
    imgStart: true,
    img: require('../../images/privesWC.jpg'),
    alt: 'mobile',
    dark: false,
    primary: true,
    darkText: true,
     

}

export const homeObjThree = {
    id: 'voda',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'O všechno se postaráme ',
    headline: ' Pitná  voda',
    description: 'V rámci  služby H2O provádíme, dovoz pitné  vody zavážení vodojemů včetně jejich čistění a dezinfekce, pronájem cisteren s pitnou vodou.',
    buttonLabel: 'Poptávat',
    imgStart: false,
    img: require('../../images/autaVODA.jpg'),
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: true,


    
     

}


export const homeObjFour = {
    id: 'cisteni',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Čištění a dezinfekce ',
    headline: 'Čištění jímek ',
    description: 'V rámci služby čištění jímek provídáme přistavení fekálního vozu vývoz septiku, fekální jímky  či žumpy posouzení aktualního stavu odvoz a likvidace fekálií',
    buttonLabel: 'Poptávat',
    imgStart: false,
    img: require('../../images/hovnocuc3.jpg'),
    alt: 'Car',
    dark: false,
    primary: true,
    darkText: true,


    
     

}





export const homeObjFive = {
    id: 'reflect',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Pronájem světel s obsluhou',
    headline: 'Osvětlení/Reflektory',
    description: 'V rámci služby osvětlení nabízíme  pronájem světelné techniky s obsluhou',
    buttonLabel: 'Poptávat ',
    imgStart: true,
   
    alt: 'mobile',
    dark: false,
    primary: true,
    darkText: true,
     

}