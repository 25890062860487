import React from 'react'
import { InfoContainer, InfoWrapper, InfoRow, Column1, Column2, Textwrapper, Topline, Heading, Subtitle, BtnWrap, ImgWrap, Img } from '../InfoSection2/InfoSectionElements'
import { Button } from '../ButtonElement';
import obrazekinfo from '../../images/globe.png'
import { useTranslation } from 'react-i18next';




const Index = ( imgStart) => {


    const { t, i18n } = useTranslation();
  return (
    <>
    <InfoContainer >
        <InfoWrapper id='aboutus'>
            <InfoRow imgStart={imgStart}>
                <Column1>
                    <Textwrapper>
                        <Topline> {t('Hero.info1')} </Topline>
                        <Heading >mobilnitoalety.com</Heading>
                        <Subtitle >{t('Hero.info2')}</Subtitle>
                        <BtnWrap>
                            <Button to='contact' 
                            smooth={true}
                            duration={500}
                            spy={true}
                            exact="true"
                            offset={-80}
                           


                            >{t('Hero.info3')} </Button>
                        </BtnWrap>
                       
                    </Textwrapper>
                </Column1>
                <Column2>
                    <ImgWrap>
                        <Img src={obrazekinfo}  />
                    </ImgWrap>
                </Column2>
            </InfoRow>
          
        </InfoWrapper>
       
     
                

    </InfoContainer>
    </>
  )
}

export default Index