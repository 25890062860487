import React, {useState, useEffect} from 'react';
import {FaBars, FaInstagram} from 'react-icons/fa'
import { animateScroll as scroll} from 'react-scroll';
import { FaFacebook } from 'react-icons/fa'
import Dropdown from '../DropDown/Dropdown'
import { MobileIcon, Nav, NavbarContainer, NavLinks, NavLogo, NavMenu, NavItem, NavBtn, NavBtnLink, SocialIconLink } from './NavbarElements';
import LogoImg from '../../images/LogoImg.png';
import { useTranslation } from 'react-i18next';


const Navbar = ({toggle}) => {
  const { t, i18n } = useTranslation();
  const [hover, setHover] = useState(false)
  
  const [scrollNav, setScrollNav] = useState(false)

  const changeNav = ()=> {
    if(window.scrollY >= 80) {
      setScrollNav (true)
    } else {
      setScrollNav(false)
    }
  }

  useEffect (() => {
    window.addEventListener('scroll', changeNav)
  }, [])

  const toggleHome = () => {
    scroll.scrollToTop();
  }


  
  return (
    <>
    <Nav scrollNav ={scrollNav}>
        <NavbarContainer >
            <NavLogo to='/' onClick={toggleHome} src={LogoImg}></NavLogo>
              <MobileIcon onClick={toggle} >
                <FaBars />
              </MobileIcon>
              <NavMenu>
                <NavItem>
                  <NavLinks to="domov" smooth={true} duration={500} spy={true} exact='true' offset={-80}>{t('Hero.menu1')}</NavLinks>
                </NavItem>
                <NavItem>
                  <NavLinks to="aboutus"  smooth={true} duration={500} spy={true} exact='true' offset={-80}>{t('Hero.menu2')}</NavLinks>
                </NavItem>
                <NavItem>
                  <NavLinks to="services"  smooth={true} duration={500} spy={true} exact='true' offset={-80}>{t('Hero.menu3')}</NavLinks>
                </NavItem>
                <NavItem>
                  <NavLinks to="reference"  smooth={true} duration={500} spy={true} exact='true' offset={-80}>{t('Hero.menu4')}</NavLinks>
                </NavItem>
                <NavItem>
                  <NavLinks to="kontakt" smooth={true} duration={500} spy={true} exact='true' offset={-80}></NavLinks>
                </NavItem>
              </NavMenu>
              <NavBtn>
                <NavBtnLink to="contact" smooth={true} duration={500} spy={true} exact='true' offset={-80} >{t('Hero.kontaktovat')} </NavBtnLink>
              </NavBtn>

              
             
            
            
        </NavbarContainer>
        <SocialIconLink href='https://www.facebook.com/profile.php?id=100086579054932' target='_blank' aria-label='Facebook' >
                    <FaFacebook />
                    
               </SocialIconLink>
               <SocialIconLink href='https://www.instagram.com/mobilnitoalety_eu/' target='_blank' aria-label='Facebook' >
                    <FaInstagram />
                    
               </SocialIconLink>
              
             
               <Dropdown  />
    </Nav>
    
    </>
  );
};

export default Navbar; 


