import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './gallery.css'
class GalleryThree extends React.Component {
    render() {
        return (
            <div className="contac">
            <div className="carot" >
                <h2></h2>
               
                <Carousel autoPlay interval="2000"infiniteLoop >
                    <div>
                        <img src="https://i.ibb.co/JRxjVjg/pitna-VODA.jpg" alt="" />
                        <p className="legend">Voda / H2O</p>
                    </div>
                    <div>
                        <img src="https://i.ibb.co/9NbgBqr/nadrz-VODA.jpg" alt="" />
                        <p className="legend">Voda / H2O</p>
                    </div>
                    <div>
                        <img src="https://i.ibb.co/qYnD5YW/nadrze-VODA.jpg" alt="" />
                        <p className="legend">Voda / H20</p>
                    </div>
                    <div>
                        <img src="https://i.ibb.co/kJF2y9t/auta2-VODA.jpg" alt="" />
                        <p className="legend">Voda / H20</p>
                    </div>
                    <div>
                        <img src="https://i.ibb.co/T4g0FcM/autaVODA.jpg" alt="" />
                        <p className="legend">Voda / H20</p>
                    </div>
                    
                  
                </Carousel>
            </div>
            </div>
        )
    };
}
export default GalleryThree