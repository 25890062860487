import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './gallery.css'
class GalleryTwo extends React.Component {
    render() {
        return (
            <div className="contac">
            <div className="carot" >
                <h2></h2>
               
                <Carousel autoPlay interval="2000"infiniteLoop >
                    <div>
                        <img src="https://i.ibb.co/6mzG3jH/hovnocuc3.jpg" alt="" />
                        <p className="legend">ČIŠTĚNÍ A DEZINFEKCE</p>
                    </div>
                    <div>
                        <img src="https://i.ibb.co/cgL5JtQ/hovnocuc1.jpg" alt="" />
                        <p className="legend">ČIŠTĚNÍ A DEZINFEKCE</p>
                    </div>
                    <div>
                        <img src="https://i.ibb.co/YQYm66R/hovnocuc4.jpg" alt="" />
                        <p className="legend">ČIŠTĚNÍ A DEZINFEKCE</p>
                    </div>
                    <div>
                        <img src="https://i.ibb.co/C9X1GcS/nadrze-ODDPADvozik.jpg" alt="" />
                        <p className="legend">ČIŠTĚNÍ A DEZINFEKCE</p>
                    </div>
                    <div>
                        <img src="https://i.ibb.co/0hRvg3f/ODpadauta.jpg" alt="" />
                        <p className="legend">ČIŠTĚNÍ A DEZINFEKCE</p>
                    </div>
                    <div>
                        <img src="https://i.ibb.co/LC4ghLQ/hovnocuc2.jpg" alt="" />
                        <p className="legend">ČIŠTĚNÍ A DEZINFEKCE</p>
                    </div>
                  
                </Carousel>
            </div>
            </div>
        )
    };
}
export default GalleryTwo