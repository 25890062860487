import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';




const languages = ['cz', 'en', 'fr', 'ru', 'ger'];



i18n
 
  .use(Backend)
 
  .use(LanguageDetector)
 
  .use(initReactI18next)
 
  .init({
    fallbackLng: 'cz',
    debug: true,
    whitelist: languages,

    interpolation: {
      escapeValue: false, // escape by default
    }
  });


export default i18n;