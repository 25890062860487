// src/component/Gallery.js
import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './gallery.css'
class Gallery extends React.Component {
    render() {
        return (
            <div className="contac">
            <div className="carot" >
                <h2></h2>
               
                <Carousel autoPlay interval="2000"infiniteLoop >
                    <div>
                        <img src="https://i.ibb.co/7QmHD8g/privesWC.jpg" alt="" />
                        <p className="legend">Mobilní toalety</p>
                    </div>
                    <div>
                        <img src="https://i.ibb.co/wNR68ps/Vnitrek-WC2.jpg" alt="" />
                        <p className="legend">Mobilní toalety</p>
                    </div>
                    <div>
                        <img src="https://i.ibb.co/4tBS81N/vnitrek-WC5.jpg" alt="" />
                        <p className="legend">Mobilní toalety</p>
                    </div>
                    <div>
                        <img src="https://i.ibb.co/f2cNH68/vnitreWC.jpg" alt="" />
                        <p className="legend">Mobilní toalety</p>
                    </div>
                   
                    <div>
                        <img src="https://i.ibb.co/1vKGcZ1/zadek-WCprives.jpg" alt="" />
                        <p className="legend">Mobilní toalety</p>
                    </div>
                  
                </Carousel>
            </div>
            </div>
        )
    };
}
export default Gallery