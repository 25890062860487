import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import emailjs from 'emailjs-com'
import './animate.css';
import TrackVisibility from 'react-on-screen';
import { useTranslation } from 'react-i18next';

export const Contact = () => {
  const { t, i18n } = useTranslation();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_wamc545', 'template_9vzb5gv', e.target, 'OIzo5q64Kx0tmS7As')
      .then((result) => {
          console.log(result.text);alert("Děkujeme za vaši zprávu! V nejbližší době vás budeme kontaktovat");
      }, (error) => {
          console.log(error.text);alert("FAILED...", error);
      });
  };



  

  return (
    <section className="contact" id="connect">
      <Container id="contact">
        <Row className="align-items-center">
          <Col size={12} md={6}>
           
          </Col>
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <h2>{t('Hero.contact')}</h2>
                <form onSubmit={sendEmail}>
                  <Row>
                    <Col size={12} sm={6} className="px-1">
                      <input type="text"  placeholder="Jméno" name="name" />
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="text" placeholder="Přijmení" name="lastname" />
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="email" placeholder="E-mail" name="email" />
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="tel"  placeholder="Tel. číslo" name="number"/>
                    </Col>
                    <Col size={12} className="px-1">
                      <textarea rows="6"  placeholder="Zpráva" name="zprava" ></textarea>
                      <button type="submit"><span>{t('Hero.contact1')}</span></button>
                    </Col>
                    {
                     
                      <Col>
                       
                      </Col>
                    }
                  </Row>
                </form>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Contact