import React from 'react'
import { SidebarContainer, Icon, CloseIcon,SidebarWraper, SidebarMenu, SidebarLink, SideBtnWrap, SidebarRoute, SocialLogo } from './SidebarElements';
import LogoImg from '../../images/LogoImg.png';

import { useTranslation } from 'react-i18next';


const Sidebar = ({isOpen, toggle} ) => {
    const { t, i18n } = useTranslation();


  return (
    <SidebarContainer isOpen={isOpen} onClick= {toggle} > 
        <Icon onClick={toggle} >
            <CloseIcon />
        </Icon>
        <SidebarWraper>
            <SidebarMenu>
                <SidebarLink to ='domov'  >
                {t('Hero.menu1')}
                </SidebarLink>
                <SidebarLink to ='services'>
                {t('Hero.menu2')}
                </SidebarLink>
                <SidebarLink to ='about'>
                {t('Hero.menu3')}
                </SidebarLink>
                <SidebarLink to ='about'>
                {t('Hero.menu4')}
                </SidebarLink>
            </SidebarMenu>
            <SideBtnWrap>
                <SidebarRoute to="contact" smooth={true} duration={500} spy={true} exact='true' offset={-80}>
                    Kontaktovat
                </SidebarRoute>
            </SideBtnWrap>
         
        </SidebarWraper>
    </SidebarContainer>
  )
}

export default Sidebar;                  