import React from 'react'
import { FaFacebook, FaTwitter, FaYoutube, FaInstagram } from 'react-icons/fa'
import {FooterContainer, FooterLinkItems, FooterLinkTitle, FooterLinksWrapper,FooterLinksContainer, FooterWrap, FooterLink } from './FooterElements'
import { SocialMedia, SocialMediaWrap, SocialLogo, WebsiteRights, SocialIcons, SocialIconLink} from './FooterElements'
import LogoImg from '../../images/LogoImg2.png';
import { animateScroll as scroll} from 'react-scroll';


const Footer = () => {


  
  const toggleHome = () => {
    scroll.scrollToTop();
  }


  return (
    <FooterContainer>
     <FooterWrap>
       <FooterLinksContainer>
         <FooterLinksWrapper>
         <FooterLinkItems>
            <FooterLinkTitle>Služby </FooterLinkTitle>
              <FooterLink to="toalet"  smooth={true} duration={500} spy={true} exact='true' offset={-80}>Mobilní toalety </FooterLink>
              <FooterLink to="voda"  smooth={true} duration={500} spy={true} exact='true' offset={-80}>Studená a teplá voda </FooterLink>
              <FooterLink to="reflect"  smooth={true} duration={500} spy={true} exact='true' offset={-80}>Reflektory</FooterLink>
              <FooterLink to="cisteni"  smooth={true} duration={500} spy={true} exact='true' offset={-80}>Odpad </FooterLink>
              
            
           </FooterLinkItems>
          
         </FooterLinksWrapper>

         <FooterLinksWrapper>
           
          
         </FooterLinksWrapper>
       </FooterLinksContainer>
       <SocialMedia>
         <SocialMediaWrap>
           <SocialLogo to='/' onClick={toggleHome} src={LogoImg} smooth={true} duration={500} spy={true} exact='true' offset={-80}>
            
             </SocialLogo>
             <WebsiteRights>2022 © {new Date().getFullYear} All rights reserved. </WebsiteRights>
             <SocialIcons>
               <SocialIconLink href='https://www.facebook.com/Mobilnitoalety_EU-102058046007137' target='_blank' aria-label='Facebook' >
                    <FaFacebook />
               </SocialIconLink>
               <SocialIconLink href='https://www.facebook.com/Mobilnitoalety_EU-102058046007137' target='_blank' aria-label='Instagram' >
                    <FaInstagram />
               </SocialIconLink>
               <SocialIconLink href='/' target='_blank' aria-label='Youtube' >
                    <FaYoutube />
               </SocialIconLink>
               
               </SocialIcons> 
         </SocialMediaWrap>
       </SocialMedia>
       </FooterWrap> 

    </FooterContainer>

    
  )
}

export default Footer



