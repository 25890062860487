import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import LanguageIcon from '@mui/icons-material/Language';
import Flag from 'react-flagkit'
import { useTranslation } from 'react-i18next';
import './dropdown.css'

export default function SelectSmall() {
  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const { t, i18n } = useTranslation();

  function clickLanguage(lang) {
    i18n.changeLanguage(lang);
  }

  
  return (
	<div className='nations'>
    <FormControl sx={{ m: 1, minWidth: 70 }} size="small" >
      <InputLabel id="demo-select-small" > <LanguageIcon /></InputLabel>
      <Select
        labelId="demo-select-small"
        id="demo-select-small"
        value={age}
        label="Age"
        onChange={handleChange}
      >
        <MenuItem value="">
      
        </MenuItem>
        <MenuItem value={10}  onClick={() => clickLanguage('cz')}><Flag country='CZ' /></MenuItem>
        <MenuItem value={20} onClick={() => clickLanguage('en')}><Flag country='GB' /></MenuItem>
        
		  
		    
      </Select>
    </FormControl>
	</div>
	
  );
}
