import React from 'react'
import { InfoContainer, InfoWrapper, InfoRow, Column1, Column2, Textwrapper, Topline, Heading, Subtitle, BtnWrap, ImgWrap, Img } from '../InfoSection2/InfoSectionElements'
import { Button } from '../ButtonElement';
import obrazekinfo from '../../images/autaVODA.jpg'
import { useTranslation } from 'react-i18next';




const InfoSection4 = ( imgStart) => {


    const { t, i18n } = useTranslation();
  return (
    <>
    <InfoContainer  id='voda'>
        <InfoWrapper>
            <InfoRow imgStart={imgStart}>
                <Column1>
                    <Textwrapper>
                        <Topline> {t('Hero.info7')} </Topline>
                        <Heading >{t('Hero.info9')} </Heading>
                        <Subtitle >{t('Hero.info8')}</Subtitle>
                        <BtnWrap>
                            <Button to='contact' 
                            smooth={true}
                            duration={500}
                            spy={true}
                            exact="true"
                            offset={-80}
                           


                            >{t('Hero.info3')} </Button>
                        </BtnWrap>
                       
                    </Textwrapper>
                </Column1>
                <Column2>
                    <ImgWrap>
                        <Img src={obrazekinfo}  />
                    </ImgWrap>
                </Column2>
            </InfoRow>
          
        </InfoWrapper>
       
     
                

    </InfoContainer>
    </>
  )
}

export default InfoSection4