import React from 'react';
import './App.css';
import {BrowserRouter as Router} from 'react-router-dom'
import Home from './pages';

import { useTranslation } from 'react-i18next';


function App() {


  
  return (
    <Router >

      <Home />
     
      

    </Router>
  );
}

export default App;



