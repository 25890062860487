import styled from 'styled-components'
import {Link as LinkS} from 'react-scroll'

export const Button = styled(LinkS)`
        border-radius: 50px;
    background: ${({primary}) => (primary ? '#00acd5' : '#010606')};
    white-space: nowrap;
    padding: ${({big}) => (big ? '14 px 48px' : '12px 30px' )};
    color:  ${({dark}) => (dark ? '#010606' : '#FFF')};
    font-size: ${({fontBig}) => (fontBig ? '20px' : '16px')};
    
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s ease-in-out;

    &:hover {

        transition: 0.2s ease-in-out;
        background: ${({primary}) => (primary ? '#fff' : '#01BF71')};
    }
`