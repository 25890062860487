import styled from 'styled-components'
import {Link as LinkR} from 'react-router-dom'
import {Link as LinkS} from 'react-scroll'

export const Nav = styled.nav`
    background: ${({scrollNav}) => (scrollNav ? '#767676' : '#767676')};
    height: 80px;
    margin-top: -80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 10;

    
`
export const NavbarContainer = styled.div`
display: flex;
justify-content: space-between;
height: 80px;
z-index: 1;
width: 100%;
padding: 0 24px;
max-width: 1100px;

`
export const NavLogo = styled.img`


cursor: pointer;
width: 75px;
height: 75px;
display: flex;







`;

export const MobileIcon = styled.div`
    display: none;
    
    @media screen and (max-width: 768px) {
        display: block;
        position: absolute;
        top: 0;
        margin-top: 5px;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 25px;
        cursor: pointer;
        color: #fff;

    }
`

export const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    margin-right: -22px;

    @media screen and (max-width: 768px) {
        display: none;

    }
`

export const NavItem = styled.li`
    height: 80px;


`

export const NavLinks = styled(LinkS)`
    color: whitesmoke;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    font-size: 0.9rem;

    &.active {
        border-bottom: 3px solid #00acd5;

    }

`;


    export const NavBtn = styled.nav`
        display: flex;
        align-items: center;
        
        @media screen and (max-width: 768px)  {
            display: none;

            
        }
    `

    export const NavBtnLink = styled(LinkS)`
        border-radius: 1px;
        background: #00acd5;
        white-space: nowrap;
        padding: 10px 22px;
        color: blanchedalmond;
        font-size: 14px;
        outline: none;
        border: none;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        text-decoration: none;
        border-radius: 13px;

        &:hover {
            transition: all 0.2s ease-in;
            background: greenyellow;
            color: #010606;
            

        }

    `


export const SocialIconLink = styled.a`
color: whitesmoke;
font-size: 28px;
padding-top: 15px;
margin-left: 25px;
&:hover {
    color: white;
    transition: 0.3s ease-out;
}
    
    @media screen and (max-width: 768px) {
        display: none;
    }
`